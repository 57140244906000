<template>
  <div>
    <vue-loading v-if="loading"></vue-loading>
    <div v-if="loaded">
      <h3>{{ item.title }}</h3>
      <p class="normalTxt nl2br">{{ item.lead }}</p>
      <div v-if="status > 1" v-html="endHtml"></div>
      <p class="nl2br">{{ item.attentions }}</p>
      <ul class="pdfLink">
        <li v-for="(pdf, index) in item.pdf_list" :key="index">
          <a
            v-if="
              pdf.link &&
              (status === 1 || (pdf.status2_link_flag && status === 2))
            "
            :href="pdf.link"
            target="_blank"
            >{{ pdf.title }}</a
          >
          <span v-else>{{ pdf.title }}</span>
        </li>
      </ul>
      <h4>{{ item.inspection_title }}</h4>
      <!-- eslint-disable -->
      <p class="nl2br">{{ item.inspection_body }}<span v-if="item.mail_address"><br />また、<a
          v-if="status === 1 || status === 2"
          :href="`mailto:${encodeURIComponent(
            item.mail_address
          )}?subject=${encodeURIComponent(
            item.mail_title || ''
          )}&amp;body=${encodeURIComponent(
            item.mail_body || ''
          )}`"
          >電子メール</a
        ><span v-else>電子メール</span>でも受け付けます。</span></p>
      <!-- eslint-enable -->
      <h4>{{ item.contact_title }}</h4>
      <p class="nl2br">{{ item.contact_boty }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { isAfter } from 'date-fns'

export default {
  name: 'AppAssessmentDetail',
  props: {
    endHtml: {
      type: String,
      default: "<p class='red'>※終了しました。</p>",
    },
  },
  data() {
    return {
      loading: true,
      loaded: false,
      item: null,
      status: 0,
      previewStatus: 0,
    }
  },
  created() {
    const urlParams = new URLSearchParams(location.search)
    const id = urlParams.get('id')
    const previewKey = urlParams.get('previewKey')
    const previewStatus = urlParams.get('previewStatus')
    const url = process.env.VUE_APP_CMS_API_BASE_URL
    const key = process.env.VUE_APP_CMS_API_KEY
    const params = {}
    if (previewKey !== null) {
      params.draftKey = previewKey
      if (previewStatus) {
        if (/^\d+$/.test(previewStatus)) {
          this.previewStatus = parseInt(previewStatus, 10)
        }
      }
    }
    axios
      .get(url + '/assessments/' + id, {
        headers: {
          'X-API-KEY': key,
        },
        params,
      })
      .then((response) => {
        this.item = response.data
        this.loading = false
        this.loaded = true
      })
      .catch(() => {
        this.loading = false
      })
  },
  watch: {
    // 現在時刻からアセス公開ステータス（status）の値（1から3）をセット
    item: function () {
      if (this.previewStatus) {
        // プレビューかつ公開ステータスが指定されている場合はその値をセット
        this.status = this.previewStatus
        return
      }
      const status2 = this.item.status2_at
        ? Date.parse(this.item.status2_at)
        : null
      const status3 = this.item.status3_at
        ? Date.parse(this.item.status3_at)
        : null
      const now = new Date()
      if (status3 && isAfter(now, status3)) {
        this.status = 3
      } else if (status2 && isAfter(now, status2)) {
        this.status = 2
      } else {
        this.status = 1
      }
    },
  },
}
</script>

<style scoped>
.nl2br {
  white-space: pre-wrap;
}
</style>

import Vue from 'vue'
import VueLoading from 'vue-loading-template'
import utils from './mixins/utils'
import AppAssessmentList from './components/AppAssessmentList.vue'
import AppAssessmentDetail from './components/AppAssessmentDetail.vue'
import AppNewsList from './components/AppNewsList.vue'

Vue.config.productionTip = false

Vue.use(VueLoading, {
  type: 'spiningDubbles',
  color: '#008731',
})

Vue.mixin(utils)

new Vue({
  el: '#app',
  components: {
    AppAssessmentList,
    AppAssessmentDetail,
    AppNewsList,
  },
})

<template>
  <div>
    <vue-loading v-if="loading"></vue-loading>
    <p v-if="title && list.length" class="newsTitle">{{ title }}</p>
    <ul class="newsList">
      <li v-for="(item, index) in list" :key="index">
        <span>{{ dateFormart(item.display_date) }} ー </span
        ><a v-if="item.link" :href="item.link">{{ item.title }}</a>
        <span v-else>{{ item.title }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios'
import { addYears, isAfter } from 'date-fns'

export default {
  name: 'AppNewsList',
  props: {
    title: {
      type: String,
      default: '',
    },
    limit: {
      type: Number,
      default: 10000, // 指定しない場合、microcmsのデフォルト値になるためデフォルトで大きな値を指定する
    },
    limitYears: {
      type: Number,
      default: 0, // 0=無制限
    },
  },
  data() {
    return {
      loading: true,
      list: [],
    }
  },
  created() {
    const url = process.env.VUE_APP_CMS_API_BASE_URL
    const key = process.env.VUE_APP_CMS_API_KEY
    const params = {
      orders: '-display_date,-updatedAt',
    }
    if (this.limit) {
      params.limit = this.limit
    }
    axios
      .get(url + '/news', {
        headers: {
          'X-API-KEY': key,
        },
        params,
      })
      .then((response) => {
        const today = new Date()
        const dateLimit = this.limitYears
          ? addYears(today, this.limitYears * -1)
          : null
        this.list = (response.data.contents || []).filter((v) => {
          if (!dateLimit) return true
          const displayDate = v.display_date ? Date.parse(v.display_date) : null
          return displayDate && isAfter(displayDate, dateLimit)
        })
        this.loading = false
      })
  },
}
</script>

<style scoped>
.newsTitle {
  padding-top: 2em;
}
.newsTitle + .newsList {
  margin-top: 0.5em !important;
}
.vue-loading {
  padding-top: 2em;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('vue-loading'):_vm._e(),(_vm.loaded)?_c('div',[_c('h3',[_vm._v(_vm._s(_vm.item.title))]),_c('p',{staticClass:"normalTxt nl2br"},[_vm._v(_vm._s(_vm.item.lead))]),(_vm.status > 1)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.endHtml)}}):_vm._e(),_c('p',{staticClass:"nl2br"},[_vm._v(_vm._s(_vm.item.attentions))]),_c('ul',{staticClass:"pdfLink"},_vm._l((_vm.item.pdf_list),function(pdf,index){return _c('li',{key:index},[(
            pdf.link &&
            (_vm.status === 1 || (pdf.status2_link_flag && _vm.status === 2))
          )?_c('a',{attrs:{"href":pdf.link,"target":"_blank"}},[_vm._v(_vm._s(pdf.title))]):_c('span',[_vm._v(_vm._s(pdf.title))])])}),0),_c('h4',[_vm._v(_vm._s(_vm.item.inspection_title))]),_c('p',{staticClass:"nl2br"},[_vm._v(_vm._s(_vm.item.inspection_body)),(_vm.item.mail_address)?_c('span',[_c('br'),_vm._v("また、"),(_vm.status === 1 || _vm.status === 2)?_c('a',{attrs:{"href":("mailto:" + (encodeURIComponent(
          _vm.item.mail_address
        )) + "?subject=" + (encodeURIComponent(
          _vm.item.mail_title || ''
        )) + "&body=" + (encodeURIComponent(
          _vm.item.mail_body || ''
        )))}},[_vm._v("電子メール")]):_c('span',[_vm._v("電子メール")]),_vm._v("でも受け付けます。")]):_vm._e()]),_c('h4',[_vm._v(_vm._s(_vm.item.contact_title))]),_c('p',{staticClass:"nl2br"},[_vm._v(_vm._s(_vm.item.contact_boty))])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }